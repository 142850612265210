.set_start {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #ffff;
    padding-bottom: 5px;

    a{
      img{
      
        transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
        cursor: pointer;
    
    @media screen and (max-width: 500px) {
        width: 250px;
      }

      @media screen and (min-width: 2000px) {
        width: 470px;
      }
    }
  }

    
}
